import FormLabel from './FormLabel';

export default function FormInput({
    id,
    type,
    label,
    value,
    placeholder,
    required,
    disabled,
    autoComplete,
    setter,
    className,
    ref,
    onClick,
    children,
}) {
    const setterHandler = (event) => {
        event.preventDefault();
        setter(event.target.value);
    };

    return (
        <FormLabel id={id} label={label} className={className}>
            <div className="group flex">
                {children && (
                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-zinc-300 bg-white px-3 text-sm text-zinc-600 shadow-sm transition duration-300 group-hover:border-zinc-700 dark:border-zinc-700/50 dark:bg-zinc-800/70 dark:text-zinc-400">
                        {children}
                    </span>
                )}

                <input
                    type={type ?? 'text'}
                    id={id}
                    onClick={onClick}
                    autoComplete={autoComplete ? 'on' : 'off'}
                    name={id}
                    value={value}
                    placeholder={placeholder}
                    required={required}
                    disabled={disabled}
                    className={`${
                        children ? 'rounded-r-lg' : 'rounded-lg'
                    } w-full flex-1 appearance-none border border-zinc-300 py-2 px-4 text-zinc-700 placeholder-zinc-400 shadow-sm outline-none transition duration-300 group-hover:border-zinc-400 dark:group-hover:border-zinc-700 dark:border-zinc-700/50 dark:text-zinc-300 ${
                        disabled
                            ? 'bg-zinc-100 dark:bg-zinc-800'
                            : 'bg-white dark:bg-zinc-900'
                    }`}
                    onChange={(e) => {
                        setter && setterHandler(e);
                    }}
                    ref={ref}
                />
            </div>
        </FormLabel>
    );
}
